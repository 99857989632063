import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import { Form, Field, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Query } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import classNames from 'classnames';
import {
  setSuffix,
  convertSearchValues,
  convertValues,
  fromObjToUrl
} from '../../utils/helpers';
import { searchTotalCountQuery, getLocalDataQuery } from '../../queries';
import CONFIG from '../../config';
import Condition from './SearchFormCustomComponents/Condition';
import {
  setCurrentCity,
  setCurrentCityIsDeferred,
  setCurrentState,
  setError,
  setResetButtonIsDisabled
} from '../../actions';
import searchFormConditionsValues from '../../defaultData/searchFormConditionsValues';
import Button from '../Button';
import Icon from '../Icon';
import FiltersIcon from '../../public/static/images/search/icons/icon-filters.svg'; // eslint-disable-line import/no-unresolved
import CitiesGroup from './SearchCitiesGroup';
import LocationGroup from './SearchLocationGroup';
import LeasedGroup from './SearchGroups/LeasedGroup';
import HasRepairGroup from './SearchGroups/HasRepairGroup';
import BuildingTypeGroup from './SearchGroups/BuildingTypeGroup';
import CategoriesGroup from './SearchGroups/CategoriesGroup';
import RoomsGroup from './SearchGroups/RoomsGroup';
import AreaFieldsGroup from './SearchGroups/AreaFieldsGroup';
import PriceFieldsGroup from './SearchGroups/PriceFieldsGroup';
import Checkbox from './SearchFormCustomComponents/Checkbox';
import FloorsGroup from './SearchGroups/FloorsGroup';
import RentalPeriod from './SearchGroups/RentalPeriod';
import LandAreaFields from './SearchGroups/LandAreaFieldsGroup';
import RenderCount from './SearchFormCustomComponents/RenderCount';

const mapStateToProps = state => {
  return {
    chosenLocationNames: state.searchForm.chosenLocationNames,
    currentCity: state.searchForm.currentCity,
    isHiddenHeader: state.searchForm['popup-header']
  };
};

const mapDispatchToProps = dispatch => {
  return {
    currentCityIsDeferredHandler: state =>
      dispatch(setCurrentCityIsDeferred(state)),
    currentCityHandler: city => dispatch(setCurrentCity(city)),
    mutateState: state => dispatch(setCurrentState(state)),
    resetButtonIsDisabled: state => dispatch(setResetButtonIsDisabled(state)),
    setErrorUI: errorState => dispatch(setError(errorState))
  };
};

const { debugMode } = CONFIG;

const Search = ({
  isFullForm,
  getRef,
  initialFormValues,
  togglePopup,
  chosenLocationNames,
  currentCity,
  currentCityIsDeferredHandler,
  currentCityHandler,
  client,
  sorting,
  toggleFilters,
  mutateState,
  resetButtonIsDisabled,
  isHiddenHeader,
  isFiltersPopupOpen,
  setErrorUI
}) => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const formEl = getRef ? getRef() : {};
  const [citiesPopupIsOpen, setCitiesPopupIsOpen] = useState(false);
  const [locationsPopupIsOpen, setLocationsPopupIsOpen] = useState(false);
  const [locationsPopupWillOpen, setLocationsPopupWillOpen] = useState(false);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const toggleHandlerCities = () => {
    const citiesAreOpeningFromLocations =
      !citiesPopupIsOpen && locationsPopupIsOpen;
    const citiesWasOpenedFromLocations =
      citiesPopupIsOpen && locationsPopupWillOpen;
    if (citiesAreOpeningFromLocations) {
      setLocationsPopupWillOpen(true);
      setLocationsPopupIsOpen(false);
    } else if (citiesWasOpenedFromLocations) {
      setLocationsPopupWillOpen(false);
      setLocationsPopupIsOpen(true);
    }
    setCitiesPopupIsOpen(!citiesPopupIsOpen);
  };
  const toggleHandlerLocations = () => {
    setLocationsPopupIsOpen(!locationsPopupIsOpen);
    if (locationsPopupIsOpen) currentCityHandler('');
  };
  const filterHandler = values => {
    mutateState(convertSearchValues(values));
    togglePopup();
  };
  const onSubmit = async values => {
    const { url, ...queryObj } = router.query;

    await client
      .query({
        query: getLocalDataQuery(values.city_id),
        variables: {
          id: values.city_id
        },
        fetchPolicy: 'cache-only'
      })
      .then(localData => {
        const { href, as } = fromObjToUrl(
          values,
          localData.data,
          queryObj.sorting
        );
        if (toggleFilters) {
          toggleFilters();
        }

        router.push(href, as);
      });
  };

  const changeFormHandler = ({ dirty, values }) => {
    const isEqual =
      JSON.stringify(
        convertSearchValues({
          city_id: '1',
          leased: 'false',
          category_id: '1'
        })
      ) === JSON.stringify(convertValues(values));

    setTimeout(() => resetButtonIsDisabled(isEqual), 0);

    if (dirty) {
      mutateState(convertValues(values));
    }
  };

  const submitButtonClasses = classNames(
    'search-form__button search-form__button--submit',
    isFullForm ? 'search-form__button--fixed' : 'search-form__button--sticky'
  );

  return (
    <div className={classNames('search', { 'search--full': isFullForm })}>
      <Form
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          values: true
        }}
        initialValues={{
          city_id: initialFormValues.cityId || '0',
          leased:
            initialFormValues.leased !== undefined
              ? `${initialFormValues.leased}`
              : undefined,
          category_id: initialFormValues.categoryId || '1',
          room_ids: initialFormValues.roomIds,
          price_from: initialFormValues.priceFrom,
          price_to: initialFormValues.priceTo,
          area_from: initialFormValues.areaFrom,
          area_to: initialFormValues.areaTo,
          land_area_from: initialFormValues.landAreaFrom,
          land_area_to: initialFormValues.landAreaTo,
          paid_daily: initialFormValues.paidDaily,
          has_bill_of_sale: initialFormValues.hasBillOfSale,
          has_mortgage: initialFormValues.hasMortgage,
          only_residences: initialFormValues.onlyResidences,
          has_repair: initialFormValues.hasRepair,
          floor_from: initialFormValues.floorFrom,
          floor_to: initialFormValues.floorTo,
          floor_first: initialFormValues.floorFirst?.toString(),
          floor_last: initialFormValues.floorLast?.toString(),
          location_ids: initialFormValues.locationIds,
          building_type: initialFormValues.buildingType,
          sorting
        }}
        render={({ handleSubmit, submitting, values, form }) => {
          const searchVariables = {
            filter: convertSearchValues(values)
          };
          formEl.current = form;

          return (
            <form
              onSubmit={handleSubmit}
              className={classNames('search-form', {
                'search-form--full': isFullForm
              })}
              id="new_q"
              noValidate="novalidate"
              acceptCharset="UTF-8"
              method="get"
            >
              <FormSpy
                subscription={{ values: true, dirty: true }}
                onChange={changeFormHandler}
              />
              {debugMode && <RenderCount />}
              <Field name="utf8" component="input" type="hidden" value="✓" />
              <CitiesGroup
                {...{
                  values,
                  citiesPopupIsOpen,
                  toggleHandlerCities,
                  setCitiesLoading,
                  setErrorUI
                }}
              />
              <LocationGroup
                formValues={values}
                currentCity={currentCity}
                locationsPopupIsOpen={locationsPopupIsOpen}
                locationsPopupWillOpen={locationsPopupWillOpen}
                toggleHandlerCities={toggleHandlerCities}
                toggleHandlerLocations={toggleHandlerLocations}
                currentCityIsDeferredHandler={currentCityIsDeferredHandler}
                chosenLocationNames={chosenLocationNames}
                setErrorUI={setErrorUI}
              />
              <div className="search-form__group">
                <LeasedGroup />
                <CategoriesGroup setErrorUI={setErrorUI} />
                <Condition
                  when="category_id"
                  is={searchFormConditionsValues.room_ids}
                  fields={['room_ids']}
                >
                  <RoomsGroup />
                </Condition>
                <AreaFieldsGroup
                  isHiddenHeader={isHiddenHeader && !isFiltersPopupOpen}
                />
                {isFullForm && (
                  <Condition
                    when="category_id"
                    is={searchFormConditionsValues.land_area}
                    fields={['land_area_from', 'land_area_to']}
                  >
                    <LandAreaFields
                      isHiddenHeader={isHiddenHeader && !isFiltersPopupOpen}
                    />
                  </Condition>
                )}
              </div>
              <div className="search-form__group">
                <Condition
                  when="leased"
                  is={searchFormConditionsValues.only_residences.leased}
                  fields={['only_residences']}
                >
                  <Condition
                    when="category_id"
                    is={searchFormConditionsValues.only_residences.category_id}
                    fields={['only_residences']}
                  >
                    <Checkbox
                      name="only_residences"
                      labelText={t('search.form.only_residences')}
                      id="search-form-only-residences"
                    />
                  </Condition>
                </Condition>
              </div>
              {isFullForm && (
                <Condition
                  when="category_id"
                  is={searchFormConditionsValues.building_type}
                  fields={['building_type']}
                >
                  <BuildingTypeGroup />
                </Condition>
              )}
              <div className="search-form__group">
                <PriceFieldsGroup
                  isHiddenHeader={isHiddenHeader && !isFiltersPopupOpen}
                />
                {isFullForm && (
                  <Condition
                    when="leased"
                    is={searchFormConditionsValues.has_bill_of_sale}
                    fields={['has_bill_of_sale', 'has_mortgage']}
                  >
                    <Checkbox
                      name="has_bill_of_sale"
                      labelText={t('search.form.has_bill_of_sale')}
                      id="search-form-has-bill-of-sale"
                    />
                    <Checkbox
                      name="has_mortgage"
                      labelText={t('search.form.has_mortgage')}
                      id="search-form-has-mortgage"
                    />
                  </Condition>
                )}
                {isFullForm && (
                  <Condition
                    when="leased"
                    is={searchFormConditionsValues.paid_daily}
                    fields={['paid_daily']}
                  >
                    <RentalPeriod />
                  </Condition>
                )}
              </div>
              {isFullForm && (
                <Condition
                  when="category_id"
                  is={searchFormConditionsValues.has_repair}
                  fields={['has_repair']}
                >
                  <HasRepairGroup />
                </Condition>
              )}
              {isFullForm && (
                <Condition
                  when="category_id"
                  is={searchFormConditionsValues.floors}
                  fields={[
                    'floor_from',
                    'floor_to',
                    'floor_first',
                    'floor_last'
                  ]}
                >
                  <FloorsGroup
                    isHiddenHeader={isHiddenHeader && !isFiltersPopupOpen}
                  />
                </Condition>
              )}
              <Field
                name="sorting"
                component="input"
                id="sorting"
                type="hidden"
              />
              <Field
                name="per_page"
                component="input"
                id="per_page"
                type="hidden"
              />
              <Field
                name="company_id"
                component="input"
                id="company_id"
                type="hidden"
              />
              {isFullForm || (
                <Button
                  additionalClass="search-form__button search-form__button--filters"
                  id="search-form-filters-button"
                  onClickHandler={() => filterHandler(values)}
                  text={t('search.buttons.show_all_filters')}
                  stat="search-form-filters-button"
                >
                  <Icon
                    IconComponent={FiltersIcon}
                    width={14}
                    height={14}
                    color="blue"
                  />
                </Button>
              )}
              <Button
                name="button"
                type="submit"
                additionalClass={submitButtonClasses}
                id="search-form-submit-button"
                stat="search-form-submit-button"
                disabled={submitting || citiesLoading}
              >
                <Query
                  query={searchTotalCountQuery(searchVariables.filter)}
                  variables={searchVariables}
                  fetchPolicy="cache-and-network"
                  ssr={false}
                >
                  {({ loading, data, error }) => {
                    if (error) return t('search.form.show_ads');
                    const count = data?.itemsConnection.totalCount;

                    return loading || submitting ? (
                      <span className="f-loader" />
                    ) : (
                      t(setSuffix(i18n, count, 'search.form.show_n_ads'), {
                        n: count
                      })
                    );
                  }}
                </Query>
              </Button>
              {debugMode && <pre>{JSON.stringify(values, 0, 2)}</pre>}
            </form>
          );
        }}
      />
    </div>
  );
};

Search.propTypes = {
  isFullForm: PropTypes.bool,
  getRef: PropTypes.func,
  initialFormValues: PropTypes.object,
  togglePopup: PropTypes.func,
  locationIds: PropTypes.array,
  chosenLocationNames: PropTypes.array,
  values: PropTypes.shape({
    city: PropTypes.number
  }),
  currentCity: PropTypes.string,
  currentCityIsDeferredHandler: PropTypes.func,
  currentCityHandler: PropTypes.func,
  toggleFilters: PropTypes.func,
  client: PropTypes.object,
  sorting: PropTypes.string,
  mutateState: PropTypes.func,
  resetButtonIsDisabled: PropTypes.func,
  isHiddenHeader: PropTypes.bool,
  isFiltersPopupOpen: PropTypes.bool,
  setErrorUI: PropTypes.func
};

Search.defaultProps = {
  isFullForm: false,
  toggleFilters: () => {}
};

const areEqual = (prevProps, nextProps) => true; // eslint-disable-line no-unused-vars

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(withApollo(Search)),
  areEqual
);
